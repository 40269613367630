export const pageMixin = {
    methods: {
        _pMTransitionToEditPageWithQuery(name, params) {
            this.$router.push({
                name,
                params,
                query: {
                    ...this.$route?.query,
                    page: this.$route?.query?.page || 1,
                },
            });
        },
    },
};
